import React from "react";
import { Outbond_Call, img2, Pict } from "../../assets";

const CallForward = () => {
  return (
    <div>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Call<span class="span-color"> Forwarding </span>
            </h1>
            <p class="hone-p f-18">
              Call forwarding feature routes the customer's call to the right
              person at the right time which reduces the latency period and
              improve the overall communication process.
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <p>
            Call Forwarding is the prominent feature of Accexi this
            feature comes handy when the call lands on the number and that
            number is not available to take the call in this case the call can
            be routed to another number, as we come across many unavoidable
            features on daily basis because of which the number may be able to
            take the incoming call however because of call forwarding we ensure
            the business is up and running We emphasize on flexibility for
            selecting the number so that the call gets forwarded, one also has
            the option of customizing the number and just to add on that in the
            case of when the official extension will be busy the call will be
            forwarded on the employee's extension if still unanswered the same
            will be forwarded to the mobile which indeed means that calls can be
            routed outside the network as well
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img src={img2} className="img-fluid" alt="inbond call"></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Advance Features of Accexi
              </div>
              <p className="f-18">
                We at the end of the day reduces the possibility of getting the
                calls missed which is done with the help of trait as Call –Hunt,
                Call Queue Management, Call parking and so forth. In the near
                future Hosted PBX is the attribute on which business will run
                with the installation of a simple softphone on a device along
                with the connection of Internet, this will add value to the
                service as you will be able to make calls globally that too on
                the low price maintain the call quality
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CallForward;
