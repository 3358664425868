import React from "react";
import { Outbond_Call, Inbond_Call,Pict } from "../../assets";

const Callwaiting = () => {
  return (
    <div>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Call<span class="span-color"> Waiting</span>
            </h1>
            <p class="hone-p">
            Accexi has all what it takes you to provide the best customer service experience.
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <p>
            During the specific time zone there is a strong possibility that
            Business phones are occupied and more customers might want to speak
            to the representative, if you are running the business on the
            traditional phone then customer will hear the buzzy tone or message
            that their call cannot be reached now and at your end you will only
            get the notification of the missed call and might also loose the
            opportunity because of outdated technology this can be taken care
            with call waiting trait If you get the call waiting feature active
            this will ensure when the call is landing and if all the
            representatives are buzzy then the call will be added on the waiting
            queue and the representative will be informed of the same and the
            caller at the other end will be updated for the time which it will
            take to get the call through this will lessen the burden of getting
            the calls missed and in this case if the caller hung up the call it
            will come through as through the missed call . This feature in
            Accexi will improve the productivity as the calls can
            be managed aptly
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={Inbond_Call}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Accexi Call Queue Management
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Call Queue </span> Management
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  In regards to get better services with Call Waiting, Accexi
                  Networks LLC comes with the trait of Call Queue Management
                  which will help you to manage the calls in well-organized
                  manner. When the call flow is at the peak and the extensions
                  which are on are limited, then the incoming call can be
                  redirected to new extensions and the representative can handle
                  the same and will handle the same on the priority basis
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Accexi Hosted PBX
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Best Customer </span> Service
                Experience
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  Accexi has all what it takes you to provide the
                  best customer service experience. Call Waiting is based on
                  softphone technology which is easy to go on and can be
                  accessed on different devices as PC, laptop. Tablet or
                  smartphone, we provide the flexibility to enable or disable
                  the feature of call waiting
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img
                src={Outbond_Call}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={Inbond_Call}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Accexi Call Queue Management
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Prominent  </span> features
              </h1>
              <div className="mt-4">
              <ul className="p-0">
                  <li><img src={Pict} alt="check" className="width-20 pl-2"></img> &nbsp;  You have an option to switch between the calls </li>
                  <li><img src={Pict} alt="check" className="width-20 pl-2"></img> &nbsp; Can opt for call conferencing</li>
                  <li><img src={Pict} alt="check" className="width-20 pl-2"></img> &nbsp; You can disconnect all calls or any specific call.</li>
                  <li><img src={Pict} alt="check" className="width-20 pl-2"></img> &nbsp;You can go for the free trial.</li>
                  <li><img src={Pict} alt="check" className="width-20 pl-2"></img> &nbsp;It is user friendly.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};
export default Callwaiting;
