import React from "react";
import { Outbond_Call, Inbond_Call } from "../../assets";

const CallConferencing = () => {
  return (
    <div>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Call<span class="span-color"> Conferencing </span>
            </h1>
            <p class="hone-p">
            Accexi Call Conferencing trait is user friendly and you can use the features as sharing voice, image and data amalgamated on a single sheet
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <p>
            Call Conferencing is an amazing gadget that has proved to be an
            asset for the organization as it helps to communicate between the
            employer and employee thus helps indeed in saving time and money, it
            is an asset in a way that you can conduct team meeting, project
            meeting, presentation or for discussion over the performance of the
            team mates. It aids the organization in saving energy as one will
            not be required to travel in and out and through call conferencing
            one can connect as many workmate as the need may be.
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={Inbond_Call}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Accexi boon
              </div>

              <div className="mt-4">
                <p className="f-18">
                  Accexi is focused on providing user-friendly,
                  cost – effective, world class phone services to small,
                  aspiring and to big organizations. We provide additional
                  traits in order to keep your business above others as
                  Voicemail, Fax mail, do not disturb, Visual call report, call
                  waiting, Direct inward dialing, ACD, Professional Greetings,
                  Hold music, Call screening and so forth. Accexi
                  along with inbuilt features, our engineers will ensure that
                  they customize the Call Conferencing with no additional cost.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Accexi Call Conferencing
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Advanced Business</span> Communication
                with Call Conferencing
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  Accexi Call Conferencing trait is user friendly
                  and you can use the features as sharing voice, image and data
                  amalgamated on a single sheet. This feature is worth penny
                  wise as it limits the cost of travelling, staying and so
                  forth, the important fact to consider is that one can use the
                  feature of call conferencing 24*7 which will have the direct
                  impact on the business productivity. Accexi
                  enables you to avail this feature at a comparatively cheap
                  price and hence will be able to get an edge over others.
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img
                src={Outbond_Call}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CallConferencing;
