import React from "react";
import { Outbond_Call, tfn2 } from "../../assets";

const Musiconhold = () => {
  return (
    <div>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Music On <span class="span-color"> Hold</span>
            </h1>
            <p class="hone-p">
            Enhance the call waiting experience for your customers with utilities of music on hold
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <p className="f-18">
            Keeping the customer on hold is not considered to be a good practice
            and lot of studies have shown that is what they are scared of when
            calling the first point of contact is the hold time which ultimately
            leaves a negative impact on the customers however in certain
            situations it is unavoidable as hen the call volume is high, when
            the request is being placed, consulting a supervisor and so forth.
          </p>
          <br />
          <h1 className="home-text mt-3 mb-2"> Utility of Music on hold</h1>
          <p className="f-18">
            In order to understand the utility of hold and how cumbersome it may
            be without the Music on Hold, the customer will not be able to
            realize if the call has got lost or the representative is still
            there, which might irritate the customer and ultimately reciprocate
            as a loose of customer In order to overcome the same, it become all
            the more important that there should be Music on Hold and it helps
            in overcoming the boredom one can convert the same into a smart
            business deal by playing the business tone which will leave impact
            on the customers
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={tfn2}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
              Music On Hold
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Custom – </span> Tailored Messages
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  Smart Businessman will make the most of every opportunity,
                  when the customer is speaking to the representative, the
                  individual should make every effort to leave impact then why
                  one should leave the opportunity when the customer is on hold
                  and this comes along with Accexi Along with using
                  the option of playing Music on hold one can also use other
                  feasible options as playing the promotional offers, imparting
                  information about additional features introduced which will
                  enlarge the aspect of sales and upgrading the same or one can
                  also seek more clarification about the product on the same
                  call rather than having to call back and you can also get the
                  feedback from the customer while the customer is on hold as it
                  will help us to improve our services.
                </p>

                <br/>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};
export default Musiconhold;
