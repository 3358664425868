import React from "react";
import "../CallFrowording/indexforward.css";
import {
  Picture,
  Picture1,
  Picture2,
  Picture3,
  Picture4,
  Picture5,
  Picture6,
  Pict,
} from "../../assets";
const callqueuemanagement = () => {
  return (
    <div>
      <div className="homebackground">
        <div className="container text-center pt-5">
          <div className="section-padding mt-4">
            <div>
              <h1 className="page-title py-2">
                <span className="span-cr">Call Queue </span> Management
              </h1>
            </div>
            {/* <div className="para-text">

            </div> */}
          </div>
        </div>
      </div>
      <div className="section-padding">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6">
              <h1 className="page-title align-left">
                <span className="span-cr">Accexi</span> comes with
                the trait of Call Queue Management
              </h1>

              <div className="para mt-3">
                <p className="f-18">
                  The old PBX was not that advanced it could only manage one
                  call on wait while the others calls could not be taken care At
                  times when the call flow is very high, it becomes difficult to
                  manage the same as every call which is not attended and cannot
                  be tracked will be sheer loss for the organization, one can
                  overcome the situation by hiring more representatives and also
                  will be required to install more extensions which will add
                  huge cost to the business and will be time consuming process
                  as well We will always advise one to keep oneself abreast with
                  technology. Accexi is cost effective and user
                  friendly solution. Accexi comes with the trait of
                  Call Queue Management which is a flawless solution which help
                  you manage all incoming calls and the representative will be
                  completely aware of number of calls in queue and then they
                  will be in the position to decide how much time do they need
                  to take in order to wrap the call..
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="image">
                <img src={Picture} className="picture" alt="picture" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="para mt-5">
              <p className="f-18">
                <strong className="f-18">Bringing your Business at power - </strong>
                Call Queue Management holds great emphasis as this helps us to
                manage the call flow effectively and as through Accexi Networks
                LLC some features like as virtual receptionist, Advance ACD and
                so forth it is easy to get the call connected to the specific
                department with in shortest span of time and will make the
                customer feel valued. It is beneficiary to both the customer and
                the representative
              </p>
            </div>

            <div className="mt-4">
              <ul className="p-0">
                <li>
                  <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                  &nbsp; Number of the calls in the queue{" "}
                </li>
                <li>
                  <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                  &nbsp; What kind of music or business tone should be played
                </li>
                <li>
                  <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                  &nbsp; Request for patience.
                </li>
                <li>
                  {" "}
                  <img
                    src={Pict}
                    alt="check"
                    className="width-20 pl-2"
                  ></img>{" "}
                  &nbsp; Promotional offer
                </li>
              </ul>
            </div>
            <div className="para align-left mt-5">
              <p className="f-18">
                With the help of this all you are trying to keep the customer
                occupied and in case the caller disconnects before getting
                though it will come across as a missed call and they can be
                reached back
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default callqueuemanagement;
