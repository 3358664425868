import React from "react";
import { Outbond_Call, Inbond_Call,Pict,hrtgffg1 ,img1,tfn3, tfn2,tfn4} from "../../assets";

const Callcenter = () => {
  return (
    <div>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Call Center <span class="span-color">Solutions</span>
            </h1>
            <p class="hone-p">
            Get updated, customized and cost-effective communication solutions for your call center and ensure seamless conversation with the customers.
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <h1 className="home-text py-2">
            Cloud Hosted Solutions to Serve Enterprise-Grade
            <span class="span-color"> Contact Center </span>
          </h1>
          <p>
            Accexi Hosted call center solution is primary
            telecommunication solution which aid the organization to get the
            communication in the best possible way by reaching out the right
            people at the right point in time and hence making the best of the
            opportunity keeping the pace with the changing time. Our user
            friendly software includes real time visual monitoring and advanced
            dialing features which will indeed help me in improving contact
            ratio and also help in adhering to compliance Absolute Solution for
            Call Centre Needs
          </p>
          <br />
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={img1}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Inbound Call Centre –
              </div>
              <p className="hone-p mt-1">
                {/* <span class="span-colorr">Lorem Ipsum </span> is simply dummy
                text of the printing and typesetting industry. */}
                A brilliant mechanism which help the center to manage the heavy
                call flow or in other words call traffic . Its features are listed
                below
              </p>
              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Call Waiting{" "}
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Multi- Level IVR
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Advance Call Distribution.
                  </li>
                  <li>
                    {" "}
                    <img
                      src={Pict}
                      alt="check"
                      className="width-20 pl-2"
                    ></img>{" "}
                    &nbsp; Music on hold{" "}
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Queue Management{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Outbound Call center –
              </div>
              <p className="hone-p mt-1">
                allows one to make calls which indeed increases the productivity
                and reduces the agents idle time, one can make the best use of
                this by using the listed features
              </p>
              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Schedule call back
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Predictive dialing
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Automatic Script fill up.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Answering machine detection.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Internal DNC.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Robo calling.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Dynamic caller id.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img
                src={tfn2}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 ">
              <img
                src={tfn4}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Blended Call Centre-
              </div>
              <p className="hone-p mt-1">
                this service is to maintain the balance between the outbound and
                inbound calls, it is the perfect blend which helps to reduce the
                idle time of the agent and they are to seamlessly handle both
                inbound and outbound calls. It comes with the listed features
              </p>
              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Intelligent Dialer Functions{" "}
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Call Transfer
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Real time visual call display.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Conference Bridge.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Internal DNC.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Robo calling.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Music on Hold.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Call Recording.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Call Barge in.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Custom Call Dispositions.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={tfn3}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <p className="hone-p mt-1">
                The Accexi Call Centre Solutions Benefits
              </p>
              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Delivers a well-balanced platform which will be
                    surely cost effective.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Ensure smooth movement between inbound and outbound
                    calls.
                  </li>
                  <li >  <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Ensures no delay and quality is also maintained.</li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Offers real time monitoring which will indeed help in
                    compliance abiding.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Ensures that data is analyzed properly.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Helps in increasing the reachability.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Abreast of latest technology in order to meet day to
                    day demands.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Callcenter;
